import { User } from "firebase/auth";

import { ApiUserProfile, fetchCurrentUser } from "../lib/api/auth";
import { useQuery, UseQueryResult } from "react-query";

/**
 * If the user is logged in, loads and returns the ApiOrganizationUser record we
 * have stored in Firebase.
 *
 * Returns null if user is not logged in as well as during the loading process.
 */
export default function useUserProfileQuery(
  firebaseUser: User | null | undefined
): UseQueryResult<ApiUserProfile> {
  const uid = firebaseUser && firebaseUser.uid;
  return useQuery(["userProfile", { uid }], fetchCurrentUser, {
    enabled: !!uid,
  });
}
