import React from "react";
import { getApp } from "@firebase/app";
import { User, getAuth } from "@firebase/auth";
/**
 * Hook for getting the currently-authed Firebase user.
 *
 * @returns undefined if login is pending (at app start-up), null if the user is
 * logged out, and the firebase.auth.User object if they’re logged in.
 */
export default function useFirebaseUser() {
  const [firebaseUser, setFirebaseUser] = React.useState<
    User | undefined | null
  >(undefined);

  React.useEffect(() => {
    const app = getApp();
    const auth = getAuth(app);
    return auth.onAuthStateChanged(
      (firebaseUser) => {
        setFirebaseUser(firebaseUser);
      },
      (err) => {
        // TODO(fiona): Not sure what to do in this case; as an internal error,
        // logging the user out may just be the best thing we can do. Perhaps
        // this should go to Sentry? The old code conflated these errors with
        // e.g. "wrong password" errors as all stored in the authError state,
        // but they’re reached in different ways.
        console.error(err);
        setFirebaseUser(null);
      }
    );
  });

  return firebaseUser;
}
