import { Routes, Route, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  WithAuth,
  WithLoggedInAuth,
  WithLoggedOutAuth,
} from "./components/Auth";
import SignIn from "./components/SignIn";
import AppSwitch from "./components/AppSwitch";
import SignOut from "./components/SignOut";
import ForgotPassword from "./components/ForgotPassword";
import TokenSignUp from "./components/TokenSignup";

// Used to inform Sentry about :params in the URLs. See:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRouting = () => (
  <SentryRoutes>
    <Route
      path="/signup/:role/:token"
      element={<WithAuth>{(auth) => <TokenSignUp auth={auth} />}</WithAuth>}
    />
    <Route
      path="/signOut"
      element={<WithAuth>{(auth) => <SignOut auth={auth} />}</WithAuth>}
    />
    <Route
      path="/forgotPassword"
      element={
        <WithLoggedOutAuth>
          {(auth) => <ForgotPassword auth={auth} />}
        </WithLoggedOutAuth>
      }
    />
    <Route
      path="/appSwitch"
      element={
        <WithLoggedInAuth>
          {(auth) => <AppSwitch auth={auth} />}
        </WithLoggedInAuth>
      }
    />
    {/* Sign In */}
    <Route
      path="/"
      element={
        <WithAuth>
          {(auth) =>
            auth.status === "logged in" ? (
              <AppSwitch auth={auth} />
            ) : (
              <SignIn auth={auth} />
            )
          }
        </WithAuth>
      }
    />
    {/* Default, redirects to Sign In */}
    <Route path="*" element={<Navigate to="/" />} />
  </SentryRoutes>
);
