// Copied from hf-web and modified/added to for the login app

const buttonBase = `
whitespace-nowrap inline-flex items-center justify-center px-2 py-1 border
border-transparent rounded-md shadow-sm text-base font-medium
disabled:cursor-not-allowed hover:shadow flex w-full
`;
export const button = `${buttonBase} text-gray-700 border-gray-100 disabled:text-gray-300`;
export const buttonActive = "shadow-inner bg-gray-50";
const buttonLargeBase = `${buttonBase} px-4 py-2`;
export const buttonSecondary = `${buttonLargeBase} text-gray-600 bg-gray-50 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-300`;
export const buttonPrimary = `
${buttonLargeBase} text-white bg-primary hover:bg-cyan-800 disabled:bg-gray-200 disabled:text-gray-300 disabled:cursor-not-allowed
`;

export const link = `text-blue-600 hover:text-blue-500`;
export const errorText = "text-sm text-red-500 pt-2";

export const formInput =
  "block w-full border px-3 py-2 border-gray-300 rounded";
export const formInputLabel = "block text-sm leading-6 font-medium";

export const contentWrapper =
  "flex min-h-full flex-1 flex-col justify-center align-items-center px-6 py-12 lg:px-8";

export const section = "sm:mx-auto sm:w-full sm:max-w-sm";
export const spacedSection = `${section} space-y-6`;
export const sectionHeader =
  "flex items-center justify-between flex-wrap gap-6";
export const sectionTitle = "text-xl font-bold";
export const sectionBody = "pt-6 space-y-12";
