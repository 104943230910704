import React from "react";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { AuthProvider } from "./components/Auth";
import { SentryUserListener, initSentry } from "./components/Sentry";
import Loading from "./components/Loading";
import { AppRouting } from "./Routes";
import { initializeFirebase } from "./lib/firebase";

import "./App.css";

initializeFirebase();
initSentry();
const queryClient = new QueryClient();

function App() {
  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AuthProvider renderLoading={() => <Loading />}>
              <SentryUserListener />
              <AppRouting />
            </AuthProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
