import React from "react";
import { HYDROFORECAST, LENS, getURLKind } from "../utils";

const _PUBLIC_URL = process?.env?.PUBLIC_URL;

const BRANDING_SRC: {
  [key: string]: { logo: string; icon: string; marketingLink: string };
} = {
  [LENS]: {
    logo: `${_PUBLIC_URL}/lens_logo.svg`,
    icon: `${_PUBLIC_URL}/lens_icon.svg`,
    marketingLink: "https://www.upstream.tech/lens",
  },
  [HYDROFORECAST]: {
    logo: `${_PUBLIC_URL}/hydroforecast_logo.svg`,
    icon: `${_PUBLIC_URL}/hydroforecast_icon.svg`,
    marketingLink: "https://www.upstream.tech/hydroforecast",
  },
  DEFAULT: {
    logo: `${_PUBLIC_URL}/upstream_logo.svg`,
    icon: `${_PUBLIC_URL}/upstream_icon.svg`,
    marketingLink: "https://www.upstream.tech/",
  },
};

export const getLensLogo = () => BRANDING_SRC[LENS].logo;
export const getLensIcon = () => BRANDING_SRC[LENS].icon;
export const getHydroforecastLogo = () => BRANDING_SRC[HYDROFORECAST].logo;
export const getHydroforecastIcon = () => BRANDING_SRC[HYDROFORECAST].icon;
export const getDefaultLogo = () => BRANDING_SRC["DEFAULT"].logo;
export const getDefaultIcon = () => BRANDING_SRC["DEFAULT"].icon;

interface Props {
  productUrl: string | null;
}

const BrandingHeader: React.FunctionComponent<Props> = ({
  productUrl,
}: Props) => {
  const urlKind = getURLKind(productUrl);
  const brandingSrc = BRANDING_SRC[urlKind || "DEFAULT"];
  return (
    <img
      className="mx-auto h-10 w-auto cursor-pointer my-10"
      src={brandingSrc.logo}
      alt="Upstream Product Branding"
      onClick={() => (window.location.href = brandingSrc.marketingLink)}
    />
  );
};

export default BrandingHeader;
