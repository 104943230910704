import { useQuery, UseQueryResult } from "react-query";

import { ApiOrganization, ApiUserProfile } from "../lib/api/auth";
import { fetchOrganization } from "../lib/api/auth";

function makeQueryKey(organizationId: ApiOrganization["id"]) {
  return ["organization", organizationId];
}

/**
 * Returns the Organization for the given user profile.
 */
export default function useOrganizationQuery(
  userProfile: ApiUserProfile | undefined
): UseQueryResult<ApiOrganization> {
  const organizationId = userProfile?.organizationKey;
  return useQuery(makeQueryKey(organizationId!), () => {
    if (organizationId) {
      return fetchOrganization(organizationId);
    } else {
      return null;
    }
  });
}
