import { initializeApp } from "firebase/app";
import { isStaging, isProd } from "./env";

const firebaseConfig = {
  apiKey: "AIzaSyD4-aU00QSfSlvcppAZoe9issOBSb3R6MQ",
  authDomain:
    isProd && !isStaging // NOTE: isStaging is separate from NODE_ENV and isProd == true on staging builds
      ? "login.upstream.tech" // https://firebase.google.com/docs/auth/web/redirect-best-practices#update-authdomain
      : "monitron-dev.firebaseapp.com",
  databaseURL: "https://monitron-dev.firebaseio.com",
  projectId: "monitron-dev",
  storageBucket: "monitron-dev.appspot.com",
  messagingSenderId: "579167012312",
  appId: "1:579167012312:web:8929ba8faaeac8ec039ead",
  measurementId: "G-7D2Y9G00BN",
};

export function initializeFirebase() {
  initializeApp(firebaseConfig);
}
